import React from "react"
import { Reset } from "styled-reset"
import { ThemeProvider, createGlobalStyle } from "styled-components"
import { normalize } from "polished"
import { theme } from "./src/utils/themeGet"
import { parseTheme } from "@sqymagma/theme"
import _mainTheme from "./src/themes/theme"
import Fonts from "./src/fonts/gilroy/font"
import "./src/styles/table.css"

// TODO: Fix typos import
import "./src/fonts/gilroy-font.css"

const defaultTheme = parseTheme({ ..._mainTheme })

const GlobalStyles = createGlobalStyle`
  ${normalize()}
  @import url('//fonts.googleapis.com/css2?family=Work+Sans:wght@400;600;700&display=swap');
  body {
    font-family: ${theme("fontFamily.primary")};
    line-height: ${theme("lineHeightBase")};
    color: ${theme("color.text01")};
    background-color: ${theme("color.primaryBackground")};
    min-height: 100%;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0;
  }
  html {
    box-sizing: border-box;
  }
  * { box-sizing: border-box; }
  a { color: inherit; text-decoration: none; }
  img { display: block; width: 100%; max-width: 100%; }
  em { font-style: italic; }
  h1,h2,h3,h4,h5,h6 {margin:0; padding:0;}
  table {
    border-collapse: separate;
    border-spacing: 2px;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  @keyframes slideFromRight {
    from {
      opacity:0;
      transform: translateX(-90px)
    }
    to {
      opacity:1;
      transform: translateX(0px)
    }
  }
  @keyframes pulse {
    0% {
      transform: scale(1.2);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
    }
  
    70% {
      transform: scale(1.45);
      box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
    }
  
    100% {
      transform: scale(1.2);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }
  @keyframes rotate {
    0% {
      transform: scale(1.1) rotate(-270deg);
    }
  
    100% {
      transform: scale(1.2) translateX(10px);
    }
  }
`



export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <>
        <Fonts />
        <Reset />
        <GlobalStyles />
        {element}
      </>
    </ThemeProvider>
  )
}


// This handles scroll positions on page transitions 

const transitionDelay = 500;

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if(location.action==='PUSH') {
    window.setTimeout(()=>window.scrollTo(0,0), transitionDelay)
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0,0])),
      transitionDelay
    )
  }
  return false
}