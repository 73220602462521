import { parseTheme } from "@sqymagma/theme";
import React from "react";
import { ThemeProvider } from "styled-components";
import _defaultTheme from "../themes/theme.json";
import Helmet from "react-helmet";
import Footer from "../components/Footer";
import Navigation from "../components/SiteNavigation/Index";
import Transition from "../components/Transition";
import SEO from "../components/Seo";
const defaultTheme = parseTheme(_defaultTheme);


const TimelineLayout = ({ children, path, location }) => {
  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]');
  }


  return (
    <ThemeProvider theme={defaultTheme}>
      <React.Fragment>
        <SEO
          title={`Cronología del coronavirus`}
          favicon={`/images/svg/virus-blue-ico.svg`}
          image={`/images/open-graph-image-blue.png`}
          description={`🦠Información sobre la pandemia del coronavirus abierta y clara, resumida en hechos y gráficos para el análisis. Información Datos y hechos se entrelazan para enriquecerse mutuamente y mejorar la comunicación.`}
          keywords={`cronologia`}
        />
        <Navigation path={path}/>
        <Transition location={location}>{children}</Transition>
        <Footer />
      </React.Fragment>
    </ThemeProvider>
  );
};

export default TimelineLayout;
