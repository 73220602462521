import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { theme } from "../../utils/themeGet"
import { Box, Flex, Text } from "@sqymagma/elements"
import IconSystem, { MenuClose } from "../IconSystem"

export default ({ toggleMenu, isMenuOpen, navTextColor, navBgColor } ) => {
 
    const navData = useStaticQuery(graphql`
    query MyQuery {
        allJavascriptFrontmatter(
            filter: { frontmatter: { path: { regex: "/semana-[0-9]+/" } } }
            sort: { fields: frontmatter___date, order: DESC }
        ) {
            nodes {
            frontmatter {
                title
                path
                error
                week
                month
                day
                monthNumber
                date
            }
            }
        }
        }
    `)



    // Group data by months (aux fn)
    const groupBy = (calendarData, key) =>
        calendarData.reduce(
        (result, item) => ({
            ...result,
            [item[key]]: [...(result[item[key]] || []), item],
        }),
        {}
    )



    const [ calendarData, setCalendarData ] = useState(null)

    // Month navigation 
    const [ state, setState ] = useState(null)

    
    useEffect(() => {
        // Format queried data
        // let calendarData = navData.allJavascriptFrontmatter.nodes
        const options = { month: "long", day: "numeric" }
  
        let dataFromQuery = navData.allJavascriptFrontmatter.nodes
        dataFromQuery = dataFromQuery.map((dayPageData, idx, arr) => {
                // Pass numeric date to string date
                dayPageData.frontmatter.formattedDate = new Date(dayPageData.frontmatter.date).toLocaleDateString("es-ES", options)
                
                // Ensure month is lowercase
                dayPageData.frontmatter.month = dayPageData.frontmatter.month.toLowerCase()

                // Get lastDayOfWeek and added next property
                dayPageData.frontmatter.lastDayOfWeek =
                idx > 0
                    ? arr[idx - 1].frontmatter.week !== dayPageData.frontmatter.week &&
                    dayPageData.frontmatter.week
                    : dayPageData.frontmatter.week
                
                // Return object with new properties
                return dayPageData.frontmatter
            }
        )
        setCalendarData(groupBy(dataFromQuery, "month"))
    }, [navData])


    useEffect(() => {
        if(calendarData) {
            setState({
                currentMonthIdx : 0,
                currentMonth: Object.values(calendarData)[0][0].month,
                prevMonth: Object.values(calendarData)[1][0].month,
                nextMonth: null
            })
        }
    }, [calendarData])


    const goPrevMonth = () => {
        const { currentMonthIdx } = state
        const prevMonthIdx = Object.values(calendarData).length > currentMonthIdx ? currentMonthIdx + 1 : null
        if (prevMonthIdx) {
            setState({
                ...state, 
                currentMonth: Object.values(calendarData)[prevMonthIdx][0].month,
                prevMonth: ( prevMonthIdx === Object.values(calendarData).length - 1 ) ?  null : Object.values(calendarData)[prevMonthIdx + 1][0].month,
                nextMonth: Object.values(calendarData)[currentMonthIdx][0].month,
                currentMonthIdx: prevMonthIdx,
                disablePrevMonth: ( prevMonthIdx === Object.values(calendarData).length - 1 ) &&  null 
            })
        }

    }

    const goNextMonth  = () => {
        const { currentMonthIdx } = state
        const nextMonthIdx = currentMonthIdx > 0 ? currentMonthIdx - 1 : null
            setState({
                ...state, 
                currentMonth: Object.values(calendarData)[nextMonthIdx][0].month,
                prevMonth: Object.values(calendarData)[currentMonthIdx][0].month,
                nextMonth: nextMonthIdx === 0 ? null : Object.values(calendarData)[nextMonthIdx - 1][0].month,
                currentMonthIdx: nextMonthIdx
            })
    }   



    return (
    <>
    <MenuCalendarOverlay 
        isMenuOpen={isMenuOpen}
        toggleMenu={() => toggleMenu('chronology')}
    />
    
    {calendarData && state && (
        <Box
        position="fixed"
        right="0"
        top="0"
        zIndex="999"
        bg="ui07"
        width={["100vw", null, "50vw"]}
        height="100vh"
        css={`
            {
            transition: all 0.4s ${theme(`easingFn.easeOut`)};
            right: ${isMenuOpen ? "0%" : "-100%"};
            border-left: 1px solid ${theme(`color.ui08`)};
            }
        `}
        >
        <MenuCalendarHeader
            navBgColor={navBgColor} 
            navTextColor={navTextColor}
            toggleMenu={() => {
                toggleMenu('chronology');
            }}
        />

        <MenuCalendarNavigation 
            goNextMonth={goNextMonth}
            goPrevMonth={goPrevMonth}
            {...state}
        />

       <Box overflow="auto" height="85vh">
       {calendarData && (
            <Text as="ul" display="flex" flexDirection="column">
            {Object.values(calendarData)[state.currentMonthIdx].map((menuItem, idx, arr) => {
                return (
                <Text key={idx} as="li" textStyle="s" py="10px" px="m" color="text03"
                    css={`
                        {
                            transition: all 0.4s ${theme(`easingFn.easeOut`)};
                            border-bottom: 1px solid ${theme(`color.ui08`)};

                            &:hover {
                                color: ${theme("color.text01")};
                                background-color: ${theme("color.brand04")}
                            }
                        }
                    `}
                >

                    <a href={menuItem.path} width={1}
                    onClick={() => toggleMenu('chronology')}
                    >
                        <Flex justifyContent="space-between" alignItems="center" width={1}>
                            <Text width={1/3} textStyle="xl">{menuItem.title}</Text>
                            <Text width={1/3} textStyle="xl-semi" textAlign="center">{menuItem.formattedDate}</Text>
                            <Text width={1/3} textStyle="label" color="brand05" caps textAlign="right">{menuItem.lastDayOfWeek}</Text>
                        </Flex>
                    </a>
                </Text>
            )})}
            </Text>
        )}
        </Box>
       </Box>

    )}
    </>
)
}


const MenuCalendarOverlay = ({isMenuOpen, toggleMenu}) => (
    <Box
    bg={isMenuOpen ? "overlay01" : ""}
    opacity={isMenuOpen ? "1" : "0"}
    position={"fixed"}
    top="0"
    left="0"
    height="100vh"
    width="100vw"
    zIndex="999"
    onClick={() => toggleMenu('chronology')}
    css={`
    {
        transition: all 0.4s ${theme(`easingFn.easeOut`)};
        pointer-events: ${isMenuOpen ? "all" : "none"};
    }
    `}
/>
)

const MenuCalendarHeader = ({navBgColor, navTextColor, toggleMenu}) => (
    <Box bg={navBgColor} color={navTextColor} px={"m"}>
        <Flex alignItems="center" justifyContent="space-between" height="48px">
        <Text textStyle="s" color={navTextColor} display="flex" alignItems="center" caps>
            <IconSystem name="calendar" height="16px" mr="8px" fill={navTextColor} />
            <Text onClick={() => toggleMenu('chronology')} as="a">
                calendario
            </Text>
        </Text>
        <MenuClose
            fill={navTextColor}
            height="18px"
            cursor="pointer"
            onClick={() => toggleMenu('chronology')}
        />
        </Flex>
    </Box>
)

const MenuCalendarNavigation = ({ currentMonth, goPrevMonth, prevMonth, goNextMonth, nextMonth }) => {
    return (
        <Box bg="ui08" px={"m"} color="text04">
            <Flex alignItems="center" justifyContent="space-between" height="48px">
            <Text 
                onClick={goNextMonth} as="a" textStyle="l-semi" caps
                css={`{
                    cursor: pointer;
                    transition: color ease-in .3s;
                    &:hover {
                        color: ${theme("color.brand04")}
                    }
                }`}
            >
                {nextMonth}
            </Text>
            <Text color={"brand04"} textStyle="l-semi" caps>
                {currentMonth}
            </Text>
            <Text 
                onClick={goPrevMonth} as="a" textStyle="l-semi" caps
                css={`{
                    cursor: pointer;
                    transition: color ease-in .3s;
                    &:hover {
                        color: ${theme("color.brand04")}
                    }
                }`}
            >
                {prevMonth && prevMonth}
            </Text>
            </Flex>
        </Box>
    )
}