import React from 'react';
import {Box} from '@sqymagma/elements';
import {MenuOpen} from "../IconSystem"

const MenuButton =  ({handleClick}) => {
    return (
        <Box 
            position="absolute"
            onClick={() => handleClick()}
            display={['block', null, 'none']}
            bg="ui08"
            height="48px"
            width="48px"
            p="xs"
            css="{
                cursor:pointer;
                transition: opacity ease .3s;

                &:hover {
                    opacity: .85;
                }
            }"
        >
            <MenuOpen fill="inverse01"/>    
        </Box>
    );
}

export default MenuButton;