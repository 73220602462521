import { Box, Text } from "@sqymagma/elements"
import React from "react"

const Section = ({ title, children, py="xl", pt=null, pb=null, backgroundSize = "cover", ...props }) => {
  return (
    <Box as="section" py={py} pt={pt} pb={pb} backgroundSize={backgroundSize} {...props}>
      <Text color="text01">{title}</Text>
      {children}
    </Box>
  )
}
export default Section
