import { parseTheme } from "@sqymagma/theme";
import React from "react";
import { ThemeProvider } from "styled-components";
import _defaultTheme from "../themes/theme.json";
import Footer from "../components/Footer";
import Navigation from "../components/SiteNavigation/Index";
import Transition from "../components/Transition";
import SEO from "../components/Seo";
const defaultTheme = parseTheme(_defaultTheme);

const RegularLayout = ({ children, path, location }) => {
  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]');
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <SEO/>
      <React.Fragment>
        <Navigation path={path} />

        <Transition location={location}>{children}</Transition>

        <Footer />
      </React.Fragment>
    </ThemeProvider>
  );
};

export default RegularLayout;
