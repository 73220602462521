import { createGlobalStyle } from "styled-components"

import gilroyBold_woff from "./Gilroy-Bold.woff"
import gilroyBold_woff2 from "./Gilroy-Bold.woff2"
import gilroyExtraBold_woff from "./Gilroy-ExtraBold.woff"
import gilroyExtraBold_woff2 from "./Gilroy-ExtraBold.woff2"
import gilroyRegular_woff from "./Gilroy-Regular.woff"
import gilroyRegular_woff2 from "./Gilroy-Regular.woff2"
import gilroySemiBold_woff from "./Gilroy-SemiBold.woff"
import gilroySemiBold_woff2 from "./Gilroy-SemiBold.woff2"

export default createGlobalStyle`
  @font-face {

    font-family: "Gilroy";
    src: local('Font Name'), local('FontName'),
    url(${gilroyRegular_woff2}) format("woff2"),
    url(${gilroyRegular_woff}) format("woff");
    font-weight: 400;
    font-style: normal;

    font-family: "Gilroy";
    src: local('Font Name'), local('FontName'),
    url(${gilroySemiBold_woff2}) format("woff2"),
    url(${gilroySemiBold_woff}) format("woff");
    font-weight: 500;
    font-style: normal;

    font-family: "Gilroy";
    src: local('Font Name'), local('FontName'),
    url(${gilroyBold_woff2}) format("woff2"),
    url(${gilroyBold_woff}) format("woff");
    font-weight: 600;
    font-style: normal;

    font-family: "Gilroy";
    src: local('Font Name'), local('FontName'),
    url(${gilroyExtraBold_woff2}) format("woff2"),
    url(${gilroyExtraBold_woff}) format("woff");
    font-weight: 800;
    font-style: normal;

  }
`
