    import React, { createRef, useState, useEffect } from "react"
    import { useStaticQuery, graphql } from "gatsby"
    import { Box } from "@sqymagma/elements"
    import { navigationData, getNavigationData } from "../../data/navigationData"
    import Header from "./Header"
    import Menu from "./Menu"
    import MenuCalendar from "./MenuCalendar"
    import MenuContext from "./MenuContext"

    export default ({ path }) => {
    
        const [ menuVars, setMenuVars ] =  useState(null);
        // get build time
        const query = useStaticQuery(graphql`
            query {
                site {
                    buildTime
                }
            }
        `)
        // vars of menu
        useEffect(() => {
            let buildTime = new Date(query.site.buildTime)
            let formatedBuildTime = buildTime.toLocaleDateString();

            const navTextColor = getNavigationData(path) ? getNavigationData(path)["textColor"] : "text01"
            const navBgColor = getNavigationData(path) && getNavigationData(path)["color"] 
            const navTextLink = getNavigationData(path)["text"]

            setMenuVars({
                navTextColor, 
                navBgColor, 
                navTextLink, 
                lastUpdate: formatedBuildTime
            })
        }, [path, query.site.buildTime])


    //sticky behaviour
    const [isSticky, setIsSticky] = useState(false)
    const [ showMenu, setShowMenu ] = useState(false)
    const menuRef = createRef()

    useEffect(() => {
        // to open menu on scroll up and scroll has reach bottom
        const scrollDetect =  () => {
            let scrollY = window.scrollY;

            if (window.innerWidth > 768 ) {
                window.addEventListener('scroll',_throttle(handleScroll,50));
            }
            
            function handleScroll(e) {  
                let isBottomOfDocument = window.scrollY >  window.document.body.offsetHeight - window.innerHeight*1.25
                let isScrollingBottom = window.scrollY > scrollY;
                let isScrollingUp = window.scrollY < scrollY

                if(isBottomOfDocument) {
                    setShowMenu(true);
                } else if (isScrollingBottom) {
                    setShowMenu(false);
                } else if (isScrollingUp) {
                    setShowMenu(true);
                } else {
                    setShowMenu(false);
                }  
                
                scrollY = window.scrollY;
            }
        }
        
        const _throttle = (fn, wait) => {
            var time = Date.now();
            return function(e) {      
                if ((time + wait - Date.now()) < 0) {
                    fn(e);
                    time = Date.now();
                }
            }
        }

        if (window.innerWidth > 768 ) {
            scrollDetect();
        }
        const cachedMenuRef = menuRef.current;
        const onScroll = ([ e ]) => setIsSticky(e.intersectionRatio < 1)
        const observer  = new IntersectionObserver(onScroll, { threshold: [1] })
        observer.observe(cachedMenuRef)

        return function() {
            observer.unobserve(cachedMenuRef)
        }
    }, [menuRef]) 



    // chronology page fn
    const isChronologyPage = path.includes(navigationData[0].link)
    const isContextPage = path.includes(navigationData[3].link)
    const [isContextMenuOpen, setIsContextMenuOpen] = useState(false)
    const [isCalendarMenuOpen, setIsCalendarMenuOpen] = useState(false)

    const toggleMenu = (page) => {
        if (page==="chronology") {
            setIsCalendarMenuOpen(!isCalendarMenuOpen);
        }
        if (page==="context") setIsContextMenuOpen(!isContextMenuOpen)
    }

    return (
        <Box as="section" position="sticky" top="-1px" zIndex="3" ref={menuRef} mb="-5px">
          { menuVars && (
        <Box
            position="relative"
            css={ isSticky && `{
                cursor: pointer;
                nav{ 
                    bottom: ${showMenu && '-31px'}; 
                };
                &:hover nav{
                    bottom: -31px;
            } }`}>
            <Header
                path={path}
                isSticky={isSticky}
                menuVars={menuVars}
            />
            <Menu
                path={path}
                isSticky={isSticky}
                navTextColor={menuVars.navTextColor}
                navBgColor={menuVars.navBgColor}
                toggleMenu={toggleMenu}
                isChronologyPage={isChronologyPage}
                isContextPage={isContextPage}
            />

            {isChronologyPage && <MenuCalendar
                toggleMenu={toggleMenu}
                isMenuOpen={isCalendarMenuOpen}
                navTextColor={menuVars.navTextColor}
                navBgColor={menuVars.navBgColor}
            />}

            {isContextPage && <MenuContext
                toggleMenu={toggleMenu}
                isMenuOpen={isContextMenuOpen}
                navTextColor={menuVars.navTextColor}
                navBgColor={menuVars.navBgColor}
            />}
        </Box>
       )}
        </Box>
)}
