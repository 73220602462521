import { Box, MaxWidth, Text, Flex } from "@sqymagma/elements";
import React from "react";
import { Magma, VirusIco, Secuoyas } from "../components/IconSystem";

const Footer = () => {
  return (
    <Box as="footer">
      <Box bg="ui07" color="text06" py="m">
        <MaxWidth>
          <Flex alignItems="center" flexDirection="column">
            <Box mb="xs">
              <VirusIco fill="ui01" width="20px" />
            </Box>
            <Text textStyle="tiny" alignText="center">
              Coronavirus COVID 19-ES · Centro de información
            </Text>
            <Flex flexDirection={["column", null, "row"]} alignItems="center">
              <Text
                textStyle="tiny"
                alignText="center"
                as="p"
                color="brand05"
                css={`
                   {
                    &:before,
                    &:after {
                      content: "|";
                      padding: 0 10px;
                      color: #fff;
                    }
                  }
                `}
              >
                <a href="mailto:coronavirus@secuoyas.com">
                  coronavirus@secuoyas.com
                </a>
              </Text>
              <Text textStyle="tiny" alignText="center" as="p">
                Fuentes de datos: ECDC, mscbs.gob.es y Depts. de Salud de las
                CCAA
              </Text>
            </Flex>
          </Flex>
        </MaxWidth>
      </Box>
      <Box bg="ui08" color="text06" py="xs">
        <MaxWidth>
          <Flex width="1" justifyContent="center" alignItems="center">
            <Text
              width="150px"
              textStyle="tiny"
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Box as="span" mr="xs">
                <Secuoyas width="16px" fill="ui01" />
              </Box>
              <a
                href="https://secuoyas.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Secuoyas
              </a>
            </Text>

            <Text px="xs" textStyle="tiny" color="text06">
              |
            </Text>

            <Text
              width="150px"
              textStyle="tiny"
              display="flex"
              justifyContent="flex-start"
            >
              Magma at core
              <Box as="span" ml="xs">
                <Magma width="20px" fill="ui01" />
              </Box>
            </Text>
          </Flex>
        </MaxWidth>
      </Box>
    </Box>
  );
};
export default Footer;
