import React from 'react';
import {
    TransitionGroup,
    CSSTransition
} from 'react-transition-group'


// Review
// https://gist.github.com/WebMaestroFr/0867a67330a9b0b8383312c4dc36ab37
// https://blog.prototypr.io/using-reactcsstransitiongroup-for-enter-exit-animations-ea100d68e72f
// const timeout = 500
// const getTransitionStyles = {
//     entering: {
//         position: 'relative',
//         opacity: 0,
//         transform: 'translateY(100%)'
//     },
//     entered: {
//         transition: `all ${timeout}ms ease-in-out`,
//         opacity: 1,
//         transform: 'translateY(0%)'
//     },
//     exiting: {
//         transition: `all ${timeout}ms ease-in-out`,
//         opacity: 0,
//         transform: 'translateY(-100%)'
//     } 
// }

const Transition = ({children, location}) => {
    return (
        <TransitionGroup>
            <CSSTransition
                key={location.key}
                classNames="swipe"
                timeout={500}
            >
                {status => (
                    <div>
                        {children}
                    </div>
                )}
            </CSSTransition>
        </TransitionGroup>
    )
}

export default Transition; 