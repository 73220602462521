import React from "react"
import { Link } from "gatsby"
import { theme } from "../../utils/themeGet"
import { Text, Inline, Flex, MaxWidth, Box } from "@sqymagma/elements"
import IconSystem from "../IconSystem"
import MenuMobile from "./MenuMobile"

const Header = ({ isSticky, path, menuVars}) => {
    const  {lastUpdate, navBgColor, navTextColor, navTextLink } = menuVars
    return (
    <>
    <MenuMobile path={path}/>
    <Flex
        bg={navBgColor}
        borderBottom={`5px solid #101010`}
        css={`{
            border-color: ${theme("color.ui08")};
        }`}
    >
    <MaxWidth>
        <Flex justifyContent="space-between" alignItems="center" py="6px" px="0">
        <Inline
            flexWrap="no-wrap"
            alignItems="center" display="flex"
            my={isSticky ? "0" : [0, null, "xs"]}
            ml={["40px", null, 0]}
            css={`{transition: margin ease-out 0.2s; }`}
        >
            <Box height={"30px"} width={"30px"} mr="8px">
                <IconSystem
                name="virusIco"
                fill={navTextColor}
                css={`{transition: height ease-out 0.2s;}`}
                />
            </Box>
            <Text
            caps
            color={navTextColor}
            fontSize={isSticky ? "11px" : ["11px", null, "17px"]}
            lineHeight="1"
            fontWeight="800"
            css={`{
                transition: all ease-out 0.2s;
            }`}
            >
            <Link to="/">
                Coronavirus <br />
                Covid-19 - Es <br />
                Centro de información
            </Link>
            </Text>
        </Inline>
        <Text
            display={isSticky ? "flex" : ["flex", null, "none"]}
            as="h2"
            caps
            color={navTextColor}
            textStyle="s"
        >
            {navTextLink}
        </Text>
        <Text textStyle="smallBodyText" display={isSticky ? "none" : ["none", null, "flex"]} color={navTextColor}>
            <IconSystem name="clock" height="16px" mr="8px" fill={navTextColor}/>
            Última actualización: {lastUpdate}
        </Text>
        </Flex>
    </MaxWidth>
    </Flex>
    </>
)
}

export default Header
