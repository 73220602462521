import { parseTheme } from "@sqymagma/theme";
import React from "react";
import { ThemeProvider } from "styled-components";
import SEO from "../components/Seo";
import _defaultTheme from "../themes/theme.json";

const defaultTheme = parseTheme(_defaultTheme);

const regularLayout = ({ children, path }) => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <SEO
        title={'Not found'}
      />
      <React.Fragment>
        {children}
      </React.Fragment>
    </ThemeProvider>
  );
};

export default regularLayout;
