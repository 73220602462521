import React from "react"
import { Box, Flex, Text, MaxWidth } from "@sqymagma/elements"
import { navigationData } from "../../data/navigationData"
import { Link } from "gatsby"
import { theme } from "../../utils/themeGet"
import MenuItemPage from "./MenuItemPage"

export default ({ isSticky, path, navTextColor, toggleCalendarMenu, isChronologyPage, toggleMenu, isContextPage}) => {
    return (
    <Box
        as="nav"
        width="100vw"
        position={isSticky ? "absolute" : "relative"}
        bottom={isSticky ? "0" : "inherit"}
        zIndex={isSticky ? "-1" : "4"}
        top={!isSticky && "-4px"}
        bg="ui08"
        css={` {
            transition: position ease-out 0.35s, bottom ease-out 0.35s;
        }`}
        boxShadow="0px 3px 4px rgba(0, 0, 0, 0.15)"
    >
        <MaxWidth mt="-1px" maxHeight="36px">
        <Flex justifyContent="flex-start" alignItems="center">
            {navigationData && (
                <>
                <MainMenu navigationData={navigationData} isSticky={isSticky} path={path} navTextColor={navTextColor}/>
                <SecondaryMenu 
                    isSticky={isSticky} path={path} navTextColor={navTextColor} 
                    toggleCalendarMenu={toggleCalendarMenu} isChronologyPage={isChronologyPage} 
                    toggleMenu={toggleMenu} isContextPage={isContextPage} 
                />
                </>
            )}
        </Flex>
        </MaxWidth>
    </Box>
    )
}


const MainMenu = ({ navigationData, isSticky, path, navTextColor }) => (
    <Text as="ul" display={["none", null, "flex"]}>
    {navigationData.filter(el => el.mainMenu).map((menuItem, idx) => {

        const currentPage = path.includes(menuItem.link) && !isSticky

        return ( <Text 
            key={idx} as="li" textStyle="s" py="10px" px={["xs","xs","xs","s"]} mr={["xs","xs",0]} caps
            color={currentPage ? navTextColor : "text05"}
            bg={currentPage ? menuItem.color : "transparent"}
            css={` {
                margin-bottom: 1px;
                transition: opacity ease 0.3s;
                &:hover {
                color: ${currentPage
                    ? "text01"
                    : theme(`color.${menuItem.color}`)};
                }
            } `}
        >
            <Link to={menuItem.link}>{menuItem.text}</Link>
        </Text>)
    })
    }
    </Text>
)

const SecondaryMenu = ({isSticky, navTextColor, path, toggleMenu, isChronologyPage, isContextPage}) => {

    const cleanedPath = path.split("").filter(el => el!=="/").join("")
    return(
    <Text as="ul" ml={"auto"} display="flex">
        {isChronologyPage &&  <MenuItemPage toggleMenu={toggleMenu}  page={{ title: "calendario", icon:"calendar", slug: "chronology" }} mr={0}/>}
        {isContextPage &&  <MenuItemPage toggleMenu={toggleMenu}  page={{ title: "índice de contenidos", icon:"contentIndex", slug: "context" }} mr={0}/>}

        {navigationData.filter(el => el.secondaryMenu).map((menuItem, idx) => (
           // show each menu item 
                // in all pages if doesnt have showInPages property 
                // or in pages indicated by showInPages property
            (!menuItem.showInPages || menuItem.showInPages.includes(cleanedPath)) && <MenuItemPage key={idx} page={{ title: menuItem.text, icon:menuItem.icon, link: menuItem.link, color:menuItem.color }} currentPage={menuItem.link.includes(cleanedPath) && !isSticky} mr={["xs","xs",0, "s"]}/>
        ))}
    </Text>)
}