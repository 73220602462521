//  *
//  * SEO component that queries for data with
//  *  Gatsby's useStaticQuery React hook
//  *
//  * See: https://www.gatsbyjs.org/docs/use-static-query/
//  */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ title, description, image, keywords, favicon }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const {
    defaultTitle,
    defaultDescription,
    titleTemplate,
    author,
    siteUrl,
    defaultImage,
    twitterUsername,
    defaultKeywords,
    defaultFavicon,
    lang
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname}`,
    image: `${siteUrl}${image || defaultImage}`,
    favicon: `${siteUrl}${favicon || defaultFavicon}`,
    keywords:` ${defaultKeywords} ${keywords && keywords}`,
  }

  return (
    <Helmet 
      title={seo.title} titleTemplate={titleTemplate}
      htmlAttributes={{lang}}
    >
      <link rel="icon" href={seo.favicon} />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="image:alt" content={seo.title} />
      <meta name="keywords" content={seo.keywords} />
      <meta name="author" content={author} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta property="og:type" content='website'/>
       
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && (
        <meta property="og:title" content={seo.title} />
      )}
      {seo.title && (
        <meta property="og:site_name" content={seo.title} />
      )}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      {seo.image && <meta property="og:image:alt" content={seo.title} />}

      
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="Contexto Covid·19-es"/>
      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />} */}
    </Helmet>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  keywords: PropTypes.string,
  favicon: PropTypes.string
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  keywords: null,
  favicon: null
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title,
        titleTemplate,
        defaultDescription: description,
        defaultKeywords: keywords,
        author,
        siteUrl,
        defaultImage: image,
        twitterUsername,
        defaultFavicon: favicon,
        lang
      }
    }
  }
`
