import React from "react";
import TimelineLayout from "./timelineLayout";
import RegularLayout from "./regularLayout";
import NotFoundLayout from "./notFoundLayout";

export default ({ children, pageContext, path, location }) => {
  if (pageContext.layout === "404") {
    return (
      <NotFoundLayout path={path} location={location}>
        {children}
      </NotFoundLayout>
    );
  }
  if (pageContext.layout === "cronologia") {
    return (
      <TimelineLayout path={path} location={location}>
        {children}
      </TimelineLayout>
    );
  }

  return (
    <RegularLayout path={path} location={location}>
      {children}
    </RegularLayout>
  );
};
