
import React, { useState, useEffect } from "react";
import { Box, Flex, Text, Stack } from '@sqymagma/elements';
import { navigationData } from '../../data/navigationData'
import { Link } from "gatsby";
import { MenuClose, LogoWhite } from "../IconSystem";
import { theme } from "../../utils/themeGet"
import MenuButton from "./MenuButton";

import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'



export default({path}) => {
    const [ isMenuOpen, setIsMenuOpen ] = useState(false)

    const _toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    const _closeMenu = () => {
        setIsMenuOpen(false);
    }

    const mobileMenu = navigationData.filter(el => el.mobileMenu)
    navigationData.filter(el => el.mobileMenu)

    return (
        <>
        <MenuButton handleClick={_toggleMenu}/>
        <Box
            position="fixed"
            top="0"
            left="-100%"
            zIndex="999"
            css={`{
                transition: all 0.5s ${theme(`easingFn.easeOut`)};
                left: ${isMenuOpen ? "0%" : "-100%"};
                transition-duration: ${isMenuOpen ? ".5s" : ".05s"};
            }`}
        >

        <BackgroundItemMenu path={path}>
                <Box
                    position="absolute"
                    right={["20px","30px",null,"70px"]}
                    top={["20px","30px",null,"70px"]}
                >
                    <Box height="25px" width="25px">
                        <MenuClose fill="inverse03"  cursor="pointer" onClick={_toggleMenu}/>
                    </Box>
                </Box>

                <Box
                    position="absolute"
                    right={["20px","30px",null,"70px"]}
                    bottom="30px"
                >
                    <a href="https://secuoyas.com" target="_blank" rel="noopener noreferrer"><LogoWhite height="30px" fill="inverse01"/></a>
                </Box>

                {navigationData && (
                    <Text as="ul" ml={["-15vw","-10vw"]}>
                        {mobileMenu.map((menuItem, idx) => (
                            <Text key={idx} as="li" textStyle="giga" mb="xs"
                                color={(path.includes(menuItem.link)) ? menuItem.color : "text02"} 
                                caps
                                css={`{
                                    margin-left: ${menuItem.overshoot && "-3px"};
                                    transition: opacity ease .3s;

                                    &:hover {
                                        color: ${theme(`color.${menuItem.color}`)};
                                    }
                                }`}
                                onClick={() => _closeMenu()}
                            >
                                <Link to={menuItem.link}>
                                    {menuItem.text}
                                </Link>
                            </Text>
                        ))}
                        <Text as="li" textStyle="l" color="inverse01" mt="l" onClick={() => _closeMenu()}>
                            <Link to="/manifiesto">
                                Nuestro manifiesto
                            </Link>
                        </Text>
                    </Text>
                )}
                {navigationData && (
                    <Stack
                        flexDirection="column"
                        vs="m"
                        position="absolute"
                        top="50%"
                        right={["30px","40px",null,"80px"]}
                        css="{transform: translate(0,-50%)}"
                    >
                        {mobileMenu.map((menuItem, idx) => (
                            <Box key={idx} width="12px" height="12px" borderRadius="50%"
                                borderColor={(path.includes(menuItem.link))  ? menuItem.color : "text04"}
                                css={`{
                                    border-width: 2px;
                                    border-style: solid;
                                }`}
                            />
                        ))}
                    </Stack>
                )}
            </BackgroundItemMenu>
        </Box>
        </>
    )
}

const BackgroundItemMenu = ({children, path}) => {
    const menuBgsData = useStaticQuery(graphql`
        query {
            allFile(filter: {relativeDirectory: {eq: "menu-bg"}}) {
                edges {
                  node {
                    name
                    childImageSharp {
                      fluid(maxWidth: 1600, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64

          
                      }
                    }
                  }
                }
            }
        }
    `)


    const [ node, setNode ] = useState (null)
    
    useEffect(() => {
        const _getBackgroundImage = () => {
            let [ currentMenuItem ] =  navigationData.filter(menuItem => path.includes(menuItem.link))
            let defaultMenuItem =  navigationData[0]
            return currentMenuItem ? currentMenuItem.image : defaultMenuItem.image
        }
        setNode(menuBgsData.allFile.edges.find(edge => edge.node.name ===  _getBackgroundImage()).node)
    }, [menuBgsData, path])

    return (
        node && (
            <Flex width="100vw" height="100vh" position="relative">
            <BackgroundImage
                Tag="section"
                fluid={node.childImageSharp.fluid}
                style={{
                    backgroundSize: "cover",
                    backgroundPosition: "top center",
                    display: "flex",
                    backgroundBlendMode: "multiply",
                    justifyContent:"center",
                    alignItems:"center",
                    height: "100vh",
                    width: "100vw"
                }}
                css={`background-color: ${p => p.theme.color.ui07};`}
            >
                {children}
            </BackgroundImage>
        </Flex>
        )
    )
}



