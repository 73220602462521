import React from "react"
import { MaxWidth, Box, Text } from "@sqymagma/elements";
import Section from "../Section";
import BackgroundImage from "gatsby-background-image";
import { useStaticQuery, graphql } from "gatsby";
import { contextAnchorMenu } from "../../data/navigationData";
import { theme } from '../../utils/themeGet'
import { MenuClose } from "../IconSystem"

export default ({isMenuOpen, toggleMenu}) => {

	const imageData = useStaticQuery(graphql`
		query {
		fileName: file(relativePath: { eq: "overlay-context-menu.jpg" }) {
			childImageSharp {
				fluid(quality: 90, maxWidth: 1600) {
				...GatsbyImageSharpFluid_withWebp_noBase64
				
				}
			}
		}
		}
	`);

	return (
	<>
		<MenuOverlay  isMenuOpen={isMenuOpen} toggleMenu={() => toggleMenu('context')}/>
		<Box  position="fixed"  right="0"  top="0" zIndex="1000"  bg="ui07"
			width={["100vw"]} css={`{
				transition: all 0.4s ${theme(`easingFn.easeOut`)};
				right: ${isMenuOpen ? "0%" : "-100%"};
				border-left: 1px solid ${theme(`color.ui08`)};
			}`}
			overflow="scroll" bottom="0"
		>
		<BackgroundImage Tag="section" fluid={imageData.fileName.childImageSharp.fluid}
			style={{ backgroundSize: "cover", backgroundPosition: "top center", position: "relative"}}
			css={`background-color: ${(p) => p.theme.color.brand04};`}
		>
			<Box position="absolute" right="20px" top="20px">
				<MenuClose fill={"inverse03"} height="18px" cursor="pointer" onClick={() => toggleMenu('context')}/>
			</Box>
			<Section display="flex" alignItems="center" py={"xxl"} overflow="auto">
				<MaxWidth>
					<Box p="xl"> 
						<Text color="brand04" textStyle="tera" mb="m" caps>
							Contexto
						</Text>
						<Navigation menu={contextAnchorMenu} toggleMenu={() => toggleMenu('context')}/>
					</Box>
				</MaxWidth>
			</Section> 
		</BackgroundImage>
	</Box>
	</>
)}

const Navigation = ({ menu, toggleMenu }) => (
<Box as="nav">
	<Text as="ul">
		{menu.map((menuItem, idx) => (
		<Text key={idx} as="li" textStyle="xxl" color="text03" mb="xs"
			css={`{
				&:hover {
					color: ${theme("color.text06")};
					transition: color ease .3s;
					cursor: pointer;
				}
			}`}>
			<a href={`#${menuItem.slug}`} width={1} onClick={() => toggleMenu('context')}>
				{menuItem.title}
			</a>
		</Text>
		))}
	</Text>
</Box>
)

const MenuOverlay = ({isMenuOpen, toggleMenu}) => (
	<Box
		bg={isMenuOpen ? "overlay01" : ""} opacity={isMenuOpen ? "1" : "0"}
		position={"fixed"} top="0" left="0" height="100vh"
		width="100vw" zIndex="999" onClick={() => toggleMenu('context')}
		css={`{
			transition: all 0.3s ${theme(`easingFn.easeOut`)};
			pointer-events: ${isMenuOpen ? "all" : "none"};
		}`}
	/>
)