// About this data
// This data is used for creating header and menu in this differents versions (the design changes slightly as per the page we're in)
// "overshoot" controls the negative margin applied to Menu Words starting with curved letters (as C or O), to optically align items in mobile version of navigation
// "mainManu" specific items that are in main navigation in opposition to those that aren't (e.g. Manifiesto is out of main menu)

export const navigationData = [
    {
        text:'Cronología',
        link:'/cronologia',
        image:'menu_story',
        color:'brand05',
        overshoot: true,
        mainMenu: true,
        mobileMenu: true
    },
    {
        text:'Visualización',
        link:'/visualizacion',
        image:'menu_dash',  
        color:'brand02',
        textColor: 'text06',
        mainMenu: true,
        mobileMenu: true
    },
    {
        text:'Datos',
        link:'/datos',
        image:'menu_api',
        color:'brand06',
        textColor: 'text06',
        mainMenu: true,
        mobileMenu: true
    },
    {
        text:'Contexto',
        link:'/contexto',
        image:'menu_story',
        color:'brand04',
        overshoot: true,
        mainMenu: true,
        mobileMenu: true
    },
    {
        text:'Recursos',
        link:'/recursos',
        image:'menu_source',
        color:'brand03',
        mainMenu: true,
        mobileMenu: true
    },
    {
        text: 'Guía de la desescalada',
        link: '/guia-desescalada',
        image: 'menu_story',
        color: 'brand07',
        mainMenu: false,
    },
    {
        text: 'Nuestro manifiesto',
        link: '/manifiesto',
        image: 'menu_story',
        color: 'brand07',
        icon: 'file',
        mainMenu: false,
        secondaryMenu: true,
    },
    {
        text: 'No te confíes',
        link: '/no-te-confies',
        image: 'menu_story',
        color: 'brand07',
        icon: 'roundAlert',
        showInPages: ['recursos', 'manifiesto', 'no-te-confies'],
        mainMenu: false,
        secondaryMenu: true,
    },
];

export const getNavigationData = (path) => {
  let found = false;
  navigationData.forEach((itemData) => {
    if (path && path.includes(itemData.link)) {
      found = itemData;
      return null;
    }
  });
  return found;
};


export const contextAnchorMenu = [
    {
        title: "El origen",
        slug: "el-origen"
    },
    {
        title: "Una zoonosis emergente",
        slug: "una-zoonosis-emergente"
    },
    {
        title: "La enfermedad de COVID-19",
        slug: "la-enfermedad-covid-19"
    },
    {
        title: "¿Cómo infecta la célula humana?",
        slug: "como-infecta-la-celula-humana"
    },
    {
        title: "Síntomas",
        slug: "sintomas"
    },
    {
        title: "Mecanismo de transmisión",
        slug: "mecanismo-de-transmision"
    },
    {
        title: "Proceso y estados",
        slug: "proceso-y-estados"
    },
    {
        title: "El desembarco en Europa",
        slug: "el-desembarco-en-europa"
    },
    {
        title: "De casos importados a transmisión local",
        slug: "de-casos-importados-a-transmision-local"
    }
]