import React from 'react'
import IconSystem from "../IconSystem"
import { Text } from "@sqymagma/elements"
import { Link } from "gatsby"
import { theme } from "../../utils/themeGet"

export default ({toggleMenu, page, currentPage, mr}) => {
    const itemColor = currentPage ? "text01" : "text05"

    return(
    <Text 
        py={["12px", null, "3px"]} px={["s", "s", "xs", "s"]}
        as="li" textStyle="label"
        bg={currentPage && page.color}
        color={itemColor}
        mt="-1px" height="36px"
        display="inline-flex" alignItems="center"
        mr={mr}
        css={`{
            transition: all ease-out 0.35s;
            cursor: pointer;
            & :hover a { color: ${theme(`color.brand04`)}} }
            & :hover * { fill: ${theme(`color.brand04`)}} }
        }`}
    >
        <IconSystem name={page.icon} height="16px" mr="8px" fill={itemColor}/>
        {page.link 
            ? <Text as="p" css={`{text-transform: lowercase}`}><Link to={page.link}>{page.title}</Link></Text>
            : <Text onClick={ () => toggleMenu ? toggleMenu(page.slug) : null } as="a" css={`{text-transform: lowercase}`}>{page.title}</Text>
        }

    </Text>)
}